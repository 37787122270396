import '../styles/index.scss';

import React, { Component } from 'react';
import { render } from "react-dom";
import {applyVueInReact} from 'veaury';

import Gallery from "react-photo-gallery";
import { Modal, ModalGateway } from "react-images";
import ImageGallery from "react-image-gallery";
import axios from 'axios';

import navbar from './navbar.vue';
import live_results from "./liveresults.vue";
import faq from "./faq.vue";
import mainpage from './mainpage.vue';
import footer from './footer.vue';
import cart from './cart.vue';
import payment from './payment.vue';
import eshop from './eshop.vue';
import racers from './racers.vue';
import registerracer from './registerracer.vue';
import registeruser from './registeruser.vue';
import forgottenpassword from './forgottenpassword.vue';
import emailconfirmation from './emailconfirmation.vue';


const delivery_cost = 79

const NavBar = applyVueInReact(navbar)
const LiveResults = applyVueInReact(live_results)
const FAQ = applyVueInReact(faq)
const MainPage = applyVueInReact(mainpage)
const Footer = applyVueInReact(footer)
const Cart = applyVueInReact(cart)
const Payment = applyVueInReact(payment)
const Eshop = applyVueInReact(eshop)
const Racers = applyVueInReact(racers)
const RegisterRacer = applyVueInReact(registerracer)
const RegisterUser = applyVueInReact(registeruser)
const ForgottenPassword = applyVueInReact(forgottenpassword)
const EmailConfirmation = applyVueInReact(emailconfirmation)

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_text: "",
            status: "",
            faq: false,
            racer: {},
            showRacerModalWindow: false,
            results: [],
            gallery_open: undefined,
            page: window.location.hash,
            photos: [],
            products: [],
            photoIsOpen: false,
            progress: false,
            currentImage: 0,
            uploadProgress: 0.0,
            login_status: {},
            cart: [],
        };
        fetch("/api/results").then(resp => resp.json()).then(json => {
            this.setState({results: json})
        })
        fetch("/api/photos").then(resp => resp.json()).then(json => {
            this.setState({photos: json})
        })
        fetch("/api/products").then(resp => resp.json()).then(json => {
            this.setState({products: json})
        })
        fetch("/api/cart").then(resp => resp.json()).then(json => {
            this.setState({cart: [...json, ...this.state.cart]})
        })
        fetch("/api/login_status").then(resp => resp.json()).then(json => {
            this.setState({login_status: json})
            if(json.delivery){
                this.setState({
                    cart: [...this.state.cart, {"name": "Doprava na " + json.delivery, "id": 2, "price": delivery_cost, "locked": true, "quantity": 1}],
                })
            }
        })
        if(window.location.search){
            let searchParams = new URLSearchParams(window.location.search);
            for(const [key, value] of searchParams){
                if (key == "refId"){
                    window.history.replaceState( {} , document.title , "/#" );
                    fetch("/api/payment_state?refId=" + value).then(resp => resp.json()).then(json =>{
                        this.setState({status: json.status, status_text: json.reason})
                    })
                }
            }
        }
        window.addEventListener("hashchange", (e) => {this.onHashChange(e)})
    }
    setStatus = (status, status_text) => {
        this.setState({status, status_text})
    }
    openGallery = (i) => {
        this.setState({gallery_open: i})
    }
    closePhoto = () => {
        this.setState(state => ({ photoIsOpen: false }))
    }
    openPhoto = (event, {index}) => {
        this.setState({
            currentImage: index,
            photoIsOpen: true,
        })
    }
    onHashChange(event){
        this.setState({page: window.location.hash, gallery_open: undefined})
    }
    editRacer = (racer) => {
        this.setState({
            racer,
            showRacerModalWindow: true,
        })
    }
    setCart = (cart) => {
        this.setState({cart})
    }
    addToCart = (product) =>{
        fetch("/api/cart/add?id=" + product.id).then(resp => resp.json()).then(json=>{
            if(json.status == "failed"){
                this.setState({
                    status_text: json.reason,
                    status: "failed",
                })
            }else{
                if(this.state.cart.filter(iter_product => {
                    if(iter_product.id == product.id){
                        iter_product.quantity += 1
                        return true
                    }else{
                        return false
                    }
                }).length == 0){
                    this.setState({
                        status_text: json.reason,
                        status: "success",
                        cart: [...this.state.cart, {...product, quantity: 1}],
                    })
                }else {
                    this.setState({
                        status_text: json.reason,
                        status: "success",
                    })
                }
            }
        })
    }
    onSubmit(event, options={}){
        if(event)
            event.preventDefault();
        let form = document.getElementById("form");
        let api_endpoint = form.action;
        let formData = new FormData(form);
        for(let option in options){
            formData.append(option, options[option]);
        }
        if(this.state.progress){
            return;
        }
        this.setState({progress: true})
        axios(
            {
                method: 'post',
                url: api_endpoint,
                data: formData,
                onUploadProgress: ({loaded, total}) => this.setState({uploadProgress: loaded/total})
            }
        ).then(({data}) => {
            this.setState({progress:false, uploadProgress: 1.0});
            if(data.status=="failed"){
                this.setState({
                    status_text: data.reason,
                    status: "failed",
                })
            } else {
                fetch("/api/login_status").then(resp => resp.json()).then(json => {
                    this.setState({login_status: json,})
                    if(json.delivery){
                        this.setState({cart:[
                            ...this.state.cart.filter(product => !product.name.startsWith("Doprava na ")),
                            {"name": "Doprava na " + json.delivery, "id": 2, "price": delivery_cost, "locked": true, "quantity": 1}
                        ],})
                    }
                })
                fetch("/api/cart").then(resp => resp.json()).then(json => {
                    this.setState({cart: [...json, ...this.state.cart.filter((product => product.name.startsWith("Doprava na ")))]})
                })
                this.setState({
                    showRacerModalWindow: false,
                    status_text: data.reason,
                    status: "success",
                })
                if (data.redirect){
                    window.open(data.redirect ,"_self")
                }
            }
        }).catch((error) => {
            this.setState({
                progress: false,
                status_text: "Chyba: " + error.response.status +", " + error.response.data.reason,
                status: "failed",
            })
        });
    }
    handleChange = (event) => {
        const { target } = event;
        this.setState((prevState) => ({
            ...prevState,
            racer: {...prevState.racer, [target.name]: target.value,}
        }));
    };
    selectDeliveryCallback(point){
        if(point){
            fetch('/api/cart/select_delivery?delivery=' + point.name).then(resp => resp.json()).then(json => {
                this.setState({
                    status_text: json.reason,
                    status: json.status
                })
                if(json.status == "success"){
                    this.setState({
                        cart: [
                            ...this.state.cart.filter(product => !product.name.startsWith("Doprava na ")),
                            {"name": "Doprava na " + point.name, "id": 2, "price": delivery_cost, "locked": true, "quantity": 1}
                        ],
                    })
                }
            });
        }
    }
    selectDelivery = () => {
        Packeta.Widget.pick("8599115769fd895", this.selectDeliveryCallback.bind(this), {
            language: "cs",
        })
    }
    togglefaq(){
        this.setState({faq: !this.state.faq});
    }
    renderCloseButton(){
        return <button class="image-gallery-close image-gallery-icon" onClick={(e) => {this.closePhoto()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" id="cross"><path fill="white" d="M70.7 64.3c1.8 1.8 1.8 4.6 0 6.4-.9.9-2 1.3-3.2 1.3-1.2 0-2.3-.4-3.2-1.3L46 52.4 27.7 70.7c-.9.9-2 1.3-3.2 1.3s-2.3-.4-3.2-1.3c-1.8-1.8-1.8-4.6 0-6.4L39.6 46 21.3 27.7c-1.8-1.8-1.8-4.6 0-6.4 1.8-1.8 4.6-1.8 6.4 0L46 39.6l18.3-18.3c1.8-1.8 4.6-1.8 6.4 0 1.8 1.8 1.8 4.6 0 6.4L52.4 46l18.3 18.3z"></path></svg>
        </button>
    }
    render(){
        let photos_gallery = [];
        let photos_carousel = [];
        if(this.state.gallery_open !== undefined){
            for (const photo of this.state.photos[this.state.gallery_open].photos){
                photos_gallery.push({
                    src: photo.thumbnail,
                    width: photo.original_width,
                    height: photo.original_height,
                })
                photos_carousel.push({
                    original: photo.original
                })
            };
        }
        return (
<div>
    <ModalGateway>
        {this.state.photoIsOpen &&
        <Modal onClose={this.closePhoto}>
            <ImageGallery
                items={photos_carousel}
                lazyLoad={true}
                showPlayButton={false}
                startIndex={this.state.currentImage}
                showThumbnails={false}
                renderCustomControls={this.renderCloseButton.bind(this)}
            />
        </Modal>
        }
    </ModalGateway>
    <NavBar
        login={Object.keys(this.state.login_status).length > 0}
        racers={(this.state.login_status.racers || []).length > 0}
        products={this.state.products.length > 0}
        cart={this.state.cart.length > 0}
        login_status={this.state.login_status}
    />
    {this.state.status == "success" &&
    <div class="absolute container-fluid alert alert-success" onClick={(e) => {this.setState({status: "", status_text:""})}}>
        {this.state.status_text}
    </div>}
    {this.state.status == "failed" &&
    <div class="absolute container-fluid alert alert-danger" onClick={(e) => {this.setState({status: "", status_text:""})}}>
        {this.state.status_text}
    </div>}
    {this.state.page == "#live_results" &&
        <LiveResults />
    }
    {this.state.page == "#cart" &&
        <Cart
            v-model={[this.state.cart, this.setCart.bind(this)]}
            onSubmit={this.onSubmit.bind(this)}
            selectDelivery={this.selectDelivery.bind(this)}
            setStatus={this.setStatus.bind(this)}
        />}
    {this.state.page == "#eshop" &&
        <Eshop products={this.state.products} addToCart={this.addToCart.bind(this)} />
    }
    {this.state.page == "#racers" &&
        <Racers racers={this.state.login_status.racers || []} onEditRacer={this.editRacer.bind(this)}/>
    }
    {this.state.page == "#register_racer" &&
        <RegisterRacer onSubmit={this.onSubmit.bind(this)}/>
    }
    {this.state.page == "#register_user" &&
        <RegisterUser onSubmit={this.onSubmit.bind(this)} />
    }
    {this.state.page == "#email_confirmation" &&
        <EmailConfirmation onSubmit={this.onSubmit.bind(this)}/>
    }
    {this.state.page == "#forgotten_password" &&
        <ForgottenPassword onSubmit={this.onSubmit.bind(this)}/>
    }
    {this.state.page == "#login" &&
    <div>
        <form id="form" action="/api/login" class="container" onSubmit={(e) => this.onSubmit(e)}>
            <h1>Přihlášení</h1>
            <div class="mb-3">
                <label for="email" class="form-label">Přihlašovací email:</label>
                <input name="email" class="form-control" type="email" />
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">Heslo:</label>
                <input type="password" class="form-control" name="password"/>
            </div>
            <button type="submit" class="btn btn-primary">Přihlásit</button>
            <button name="forgotten_password" value="true" class="btn btn-light" onClick={(e) => {this.onSubmit(e, {"forgotten_password": "true"})}}>Zapomenuté heslo</button>
        </form>
    </div>
    }
    {this.state.page == "#upload_files" &&
    <div>
        <form id="form" action="/api/upload_files" class="container" onSubmit={(e) => this.onSubmit(e)}>
            <label>Zvolte soubory <input type="file" name="files" accept=".jpg,.png,.webp,.webm,.avi,.mkv,.mp4" multiple={true}  onChange={(e)=> this.handleChangeFiles.bind(this)(e)}/></label>
            <button type="submit" class="btn btn-primary">Nahrát</button><b>progress: {(this.state.uploadProgress * 100).toFixed(1)}%</b><br />
            Nahráním fotek/videí nám dávate souhlas s použitím vaších fotek k propagaci a zvěřejněním na stránkách.
        </form>
    </div>}
    {this.state.page == "#payment" &&
        <Payment />
    }
    {this.state.page == "#gallery" &&
    <div class="container" style={{display: "flex"}}>
        {this.state.photos.map((photos, index) =>
        <div class="card" style={{"width": "18rem", margin: "10px"}}>
            <img src={'/photos/plakat_' + photos.index + '.jpg'} class="card-img-top" />
            <div class="card-body">
                <h5 class="card-title">{photos.name}</h5>
                <a href="#gallery" class="btn btn-primary" onClick={(e) => {e.preventDefault(); this.openGallery(index)}}>Otevřít galerii</a>
            </div>
        </div>
        )}
    </div>
    }
    {this.state.page == "#gallery" && this.state.gallery_open !== undefined &&
    <div class="container">
        <Gallery style={{}} photos={photos_gallery} onClick={this.openPhoto}/>
    </div>
    }
    {(this.state.faq || this.state.showRacerModalWindow) &&
        <div class="shadow"></div>}
    {this.state.showRacerModalWindow &&
        <div class="faq" onClick={(e) => this.setState({showRacerModalWindow: false})}>
            <div class="container text" onClick={(e) => e.stopPropagation()}>
                <form id="form" class="container" action="/api/change_racer" onSubmit={(e) => this.onSubmit(e)}>
                    <h1>Výměna závodníka</h1>
                    <input type="hidden" name="id" value={this.state.racer.id} />
                    <div class="mb-3">
                        <label for="first_name" class="form-label">Jméno</label>
                        <input type="text" class="form-control" id="first_name" name="first_name" value={this.state.racer.first_name} onChange={(e) => this.handleChange(e)} />
                    </div>
                    <div class="mb-3">
                        <label for="last_name" class="form-label">Přijmení</label>
                        <input type="text" class="form-control" id="last_name" name="last_name"  value={this.state.racer.last_name} onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Emailová adresa (volitelné)</label>
                        <input type="email" class="form-control" id="email" name="email"  value={this.state.racer.email} onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div class="mb-3">
                        <label for="date_of_birth" class="form-label">Datum narození</label>
                        <input type="date" class="form-control" id="date_of_birth" name="date_of_birth" max={new Date().toJSON().slice(0, 10)}  value={this.state.racer.date_of_birth} onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div class="mb-3">
                        <label for="phone" class="form-label">Telefonní číslo (volitelné)</label>
                        <input type="text" class="form-control" id="phone" name="phone"  value={this.state.racer.phone} onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div class="mb-3">
                        <label for="phone" class="form-label">Team (volitelné)</label>
                        <input type="text" class="form-control" id="team" name="team"  value={this.state.racer.team} onChange={(e) => this.handleChange(e)} />
                    </div>
                    <button type="submit" class="btn btn-primary">Vyměnit závodníka</button>
                </form>
            </div>
        </div>
    }
    {this.state.faq &&
        <FAQ togglefaq={this.togglefaq.bind(this)} />
    }
    {this.state.page == "" &&
        <MainPage togglefaq={this.togglefaq.bind(this)} results={this.state.results} />
    }
    <Footer />
</div>
        );
    }
}

const root = document.getElementById("root");
render(<Main />, root);