<template>
    <div class="container text">
        <a href="/public/reverz.pdf">Reverz</a><br />
        <a href="/public/ochrana_osobnich_udaju.pdf">Ochrana osobních údajů</a><br />
        <a href="/public/obchodni_podminky_alkator.pdf">Obchodní podmínky závodu alkátor</a><br />
        Název společnosti: Zhoor s.r.o.<br />
        IČ: 212 44 863<br />
        DIČ: Nejsme plátci DPH.<br />
        sídlo: Červenovodská 548, Králíky, 561 69<br />
        Číslo účtu: 131-2219860207/0100<br />
        E-mail: info@alkator.cz<br />
        Tel: +420 728 018 088<br />
        <img src="/public/footer.png" :style='{"width":"100%","height":"auto"}' /><br/>
        © Alkátor team 2024-2025
    </div>
</template>
