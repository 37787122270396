<script setup>
const props = defineProps(['login', 'racers', 'products', 'cart', 'login_status'])
</script>
<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container">
            <ul class="navbar-nav mb-0">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="#">Domů</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#gallery">Galerie</a>
                </li>
                <!--
                <li class="nav-item">
                    <a class="nav-link" href="#upload_files">Nahrát vlastní fotky/videa</a>
                </li>
                -->
                <li class="nav-item">
                    <a class="nav-link" href="#payment">Platba</a>
                </li>
                <li class="nav-item" v-if="!props.login">
                    <a class="nav-link" href="#login">Přihlášení</a>
                </li>
                <li class="nav-item" v-if="!props.login">
                    <a class="nav-link" href="#register_user">Registrace</a>
                </li>
                <li class="nav-item" v-if="props.racers">
                    <a class="nav-link" href="#racers">Závodníci</a>
                </li>
                <li class="nav-item" v-if="props.login">
                    <a class="nav-link" href="#register_racer">Registrovat závodníka</a>
                </li>
                <li class="nav-item" v-if="props.login">
                    <div class="nav-link">Přihlášen {{props.login_status.first_name}} {{props.login_status.last_name}} <a href="/api/logout">Odhlásit</a></div>
                </li>
                <li class="nav-item" v-if="props.products">
                    <a class="nav-link" href="#eshop">Eshop</a>
                </li>
                <li class="nav-item" v-if="props.cart">
                    <a class="nav-link" href="#cart">Košík</a>
                </li>
                <!--
                <li class="nav-item">
                    <a class="nav-link" href="#live_results">Výsledky živě</a>
                </li>
                -->
            </ul>
        </div>
    </nav>
</template>