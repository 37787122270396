<script setup>
let props = defineProps(['onSubmit'])
</script>
<template>
    <form id="form" action="/api/email_confirmation" class="container" method="post" @submit="props.onSubmit">
        <h1>Potvrzení emailu</h1>
        <div class="mb-3">
            <label for="email" class="form-label">Přihlašovací email:</label>
            <input name="email" class="form-control" type="email" />
        </div>
        <div class="mb-3">
            <label for="code" class="form-label">Kód pro potvrzení emailu:</label>
            <input type="text" class="form-control" name="code"/>
        </div>
        <button type="submit" class="btn btn-primary">Potvrdit email</button>
    </form>
</template>